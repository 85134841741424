import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../Text/Text';
import { Box, Flex } from 'rebass';
import { theme } from '../common/theme';
import { Container } from '../Grid';

const StyledNotice = styled(Box)`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  transition: all ${theme.speed.default} ease;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  position: relative;
`;

const CloseIcon = styled(Flex)`
  position: absolute;
  cursor: pointer;
  right: ${theme.space[2]}px;
  top: 50%;
  margin-top: -8px;
  z-index: 1;
`;

const StyledText = styled(Text)`
  a {
    color: inherit;
  }

  p {
    margin-bottom: 0px;
  }
`;

export const getColors = {
  white: {
    bg: 'baseWhite',
    text: 'baseBlack',
  },
  black: {
    bg: 'baseBlack',
    text: 'baseWhite',
  },
  gray: {
    bg: 'baseGrayVeryLight',
    text: 'baseBlack',
  },
  green: {
    bg: 'brandGreen',
    text: 'baseWhite',
  },
  purple: {
    bg: 'brandPurple',
    text: 'baseWhite',
  },
  violet: {
    bg: 'brandViolet',
    text: 'baseBlack',
  },
  red: {
    bg: 'brandRed',
    text: 'baseWhite',
  },
  yellow: {
    bg: 'yellowBright',
    text: 'baseBlack',
  },
  navy: {
    bg: 'brandNavy',
    text: 'baseWhite',
  },
  purpleGreen: {
    bg: 'brandViolet',
    text: 'brandPurple',
  },
  xMas: {
    bg: 'brandViolet',
    text: 'brandPurple',
  },
  greenYellow: {
    bg: 'brandGreen',
    text: 'brandYellowBright',
  },
  whiteYellow: {
    bg: 'baseWhite',
    text: 'brandRed',
  },
  whiteRed: {
    bg: 'baseWhite',
    text: 'brandRed',
  },
  blue: {
    bg: 'jaggedIce',
    text: 'baseBlack',
  },
};

const STATE_KEY = 'showNotice';
const isClient = typeof window !== 'undefined';

const getColorScheme = (color, meta) => {
  if (meta && Object.entries(meta).length !== 0) {
    return meta.colors;
  }
  return getColors[color];
};

export const Notice = props => {
  const { isActive, isDismissible, text, color, meta } = props;
  const colorScheme = getColorScheme(color, meta);

  // Initialize state based on dismissibility
  const [isOpen, setIsOpen] = useState(() => {
    if (!isDismissible) {
      return isActive; // Always show non-dismissible notices
    }
    const storedState = isClient
      ? JSON.parse(window.sessionStorage.getItem(STATE_KEY))
      : null;
    return storedState !== null ? storedState : isActive;
  });

  useEffect(() => {
    if (isDismissible) {
      window.sessionStorage.setItem(STATE_KEY, JSON.stringify(isOpen));
    }
  }, [isOpen, isDismissible]);

  if (!isActive) return null;

  return (
    <StyledNotice py={[1, 3]} bg={colorScheme.bg} isOpen={isOpen}>
      <Container>
        <Flex alignItems={['flex-start', 'center']} justifyContent="center">
          <Flex
            alignItems={['flex-start', 'center']}
            flexDirection={['column', 'row']}
          >
            {text && (
              <Box mr={[5]}>
                <StyledText
                  size="body"
                  lineHeight="1.5"
                  html={text}
                  color={colorScheme.text}
                />
              </Box>
            )}
          </Flex>
          {isDismissible && (
            <CloseIcon
              justifyContent="center"
              color={colorScheme.text}
              onClick={() => setIsOpen(false)}
              data-testid="close-notice-button"
            >
              <i className="icon icon-close" />
            </CloseIcon>
          )}
        </Flex>
      </Container>
    </StyledNotice>
  );
};

export default Notice;

Notice.defaultProps = {
  /**  text renders the Title */
  text: null,
  /**  Is the Notice Active? */
  isActive: true,
  /**  Is the Notice able to be dismissed? */
  isDismissible: true,
  /**  What is the BG color */
  color: 'yellow',
  /** Custom Colors passed in via Meta */
  meta: {},
};

Notice.propTypes = {
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isDismissible: PropTypes.bool,
  color: PropTypes.string,
  meta: PropTypes.object,
};
